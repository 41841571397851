@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-ui-secondary-200;
}

.markdown p {
  margin-bottom: 1em;
}

.markdown h1 {
  margin-top: 1em;
  font-size: 1.6em;
  font-weight: bold;
}

.markdown h2 {
  margin-top: 0.8em;
  font-size: 1.4em;
  font-weight: bold;
}

.markdown h3 {
  margin-top: 0.6em;
  font-size: 1.2em;
  font-weight: bold;
}

.markdown a {
  text-decoration: underline;
}

.markdown ul {
  margin-top: 1em;
  margin-bottom: 1em;
  list-style: disc outside none;
}

.markdown ul li {
  margin-left: 2em;
  display: list-item;
}

#iframe-crossmint-payment-element {
  width: 100%;
}

/* hide captcha badge */
.grecaptcha-badge {
  visibility: hidden !important;
}
